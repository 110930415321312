import { AppDispatch } from "app/store";
import axios from "axios";
import { getCurrentOrder, setValue } from "entities/orders/model";
import { arrToMap } from "shared/utils";
import {
  deleteFileFromState,
  setFiles,
  setLoading,
  setXls,
  setXlsReport,
  setFile,
  addFiles,
} from "./fileSlice";
import { getOffers } from "entities/offers/model";
import { setMessage } from "entities/viewer/model";
import { setError } from "entities/messages/model/messagesSlice";

export const getFiles = (ordertId?:string) => async (dispatch: AppDispatch, getState: any) => {
  const token = localStorage.getItem("token");
  dispatch(setLoading(true));
  const state = getState();
  let orderId = ordertId || getCurrentOrder(state).id;
  await axios
    .get(`/api/files/?order=${orderId}`, {
      headers: {
        Authorization: "token " + token,
      },
    })
    .then((response) => {
      const data = response.data;
      dispatch(setFiles(arrToMap(data)));
    })
    .catch((error) => {
      console.log(error);
      dispatch(setError("files/getFiles"));
    });
};

export const getOrderFiles =
  (orderId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    await axios
      .get(`/api/files/?order=${orderId}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(addFiles(arrToMap(data)));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError("files/getFiles"));
      });
  };

export const getLeadFiles =
  (leadId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    await axios
      .get(`/api/files/?lead=${leadId}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(setFiles(arrToMap(data)));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError("files/getLeadFiles"));
      });
  };

export const getQuoteFiles =
  (quoteId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    await axios
      .get(`/api/files/?quote=${quoteId}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(setFiles(arrToMap(data)));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError("files/getQuoteFiles"));
      });
  };

// se si crea dal preventivo referisce solo a offerId
export const uploadFile =
  (files: FileList) => async (dispatch: AppDispatch, getState: any) => {
    dispatch(setLoading(true));
    const token = localStorage.getItem("token");
    const state = getState();
    const orderId = getCurrentOrder(state).id;
    const offerId = state.offers.current;
    const formData = new FormData();
    for (let i = 0; i < Object.values(files).length; i++) {
      formData.append("file", files[i]);
    }
    if (offerId) formData.append("offer", offerId);
    else formData.append("order", orderId.toString());
    await axios
      .post(`/api/files/`, formData, {
        headers: {
          Authorization: "token " + token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(setMessage(data));
        dispatch(getFiles());
        dispatch(getOffers("", orderId));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError("files/uploadFile"));
      });
  };

export const uploadLeadFile =
  (files: FileList, leadId: string) => async (dispatch: AppDispatch) => {
    const formData = new FormData();
    for (let i = 0; i < Object.values(files).length; i++) {
      formData.append("file", files[i]);
    }
    formData.append("lead", leadId);
    await axios
      .post(`/api/files/`, formData, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const data = response.data;
        //dispatch(addFiles(data));
        dispatch(getLeadFiles(leadId));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError("files/uploadFile"));
      });
  };

export const uploadQuoteFile =
  (files: FileList, quoteId: string) => async (dispatch: AppDispatch) => {
    const formData = new FormData();
    for (let i = 0; i < Object.values(files).length; i++) {
      formData.append("file", files[i]);
    }
    formData.append("quote", quoteId);
    await axios
      .post(`/api/files/`, formData, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const data = response.data;
        //dispatch(addFiles(data));
        dispatch(getQuoteFiles(quoteId));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError("files/uploadFile"));
      });
  };

export const updateFile =
  (file: any) => async (dispatch: AppDispatch, getState: any) => {
    await axios
      .patch(`/api/files/${file.id}/`, file, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        dispatch(setFile(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError(error.error_message));
      });
  };

export const deleteFile =
  (id: string) => async (dispatch: AppDispatch, getState: any) => {
    const token = localStorage.getItem("token");
    await axios
      .delete(`/api/files/${id}/`, {
        headers: {
          Authorization: "token " + token,
        },
      })
      .then((response) => {
        dispatch(deleteFileFromState(id));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError("files/deleteFile"));
      });
  };

export const getXls = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  const token = localStorage.getItem("token");
  await axios
    .get(`/api/xls/`, {
      headers: {
        Authorization: "token " + token,
      },
    })
    .then((response) => {
      const data = response.data;
      dispatch(setXls(data));
    })
    .catch((error) => {
      console.log(error);
      dispatch(setError("files/getXls"));
    });
};

export const loadXls = (file: File) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  const token = localStorage.getItem("token");
  await axios
    .post(
      `/api/xls/`,
      { file: file },
      {
        headers: {
          Authorization: "token " + token,
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      const data = response.data;
      dispatch(getXls());
    })
    .catch((error) => {
      console.log(error);
      dispatch(setError("files/loadXls"));
    });
};

export const getXlsFileReport =
  (id: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    const token = localStorage.getItem("token");
    await axios
      .get(`/api/xls-proc?id=${id}`, {
        headers: {
          Authorization: "token " + token,
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(setXlsReport(data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError("files/getXlsFileReport"));
      });
  };

export const applyXlsReportChanges =
  (id: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    const token = localStorage.getItem("token");
    await axios
      .post(
        `/api/xls-proc/apply/${id}/`,
        {},
        {
          headers: {
            Authorization: "token " + token,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        dispatch(setXlsReport(data));
        dispatch(getXls());
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError("files/applyXlsReportChanges"));
      });
  };
