import React, { useState } from "react";
import { useAddManOrderMutation, useGetManOrdersQuery } from "entities/man/model/api";
import { FiltersForm } from "entities/man/ui/FiltersForm";
import { Filters } from "entities/man/model/types";
//import { CreateManOrderModal } from "entities/man/ui/CreateManOrderModal";
import { Pagination } from "shared/ui/Pagination";
import { toSearchString } from "shared/utils";
import { ManOrderList } from "./ManOrderList";
import { HistoryList } from "entities/orders/ui/HistoryList";

export const ManPage: React.FC = () => {
  const [filters, setFilters] = useState<Filters>({
    com_status: "in stock",
    search: "",
    model: "",
    trucks: "",
    prod_status: "",
    page_size: 9,
    page: 1,
    type: "",
    top: "",
    cabine: "",
    trans: "",
    susp: "",
    wheelbase: "",
    traction: "",
    engine: "",
    carload: "",
    setup: "",
    color: "",
    manager: "",
    fin_status: "",
    sale_year: "",
    invoice_year: "",
  });

  const { data, isLoading, isError, refetch } = useGetManOrdersQuery(
    toSearchString(filters)
  );

  //const [createIsOpen, setCreateIsOpen] = useState(false);

  const [
    addManOrder,
    { isLoading: isUpdating, isError: isErrorUpdate, isSuccess },
  ] = useAddManOrderMutation();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    
    if (name === 'model') {
      // Сбрасываем конфигурационные фильтры при смене модели
      setFilters((prev) => ({
        ...prev,
        [name]: value,
        type: "",
        top: "",
        cabine: "",
        trans: "",
        susp: "",
        wheelbase: "",
        traction: "",
        engine: "",
        carload: "",
        setup: "",
        color: "",
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (name === 'com_status') {
      setFilters((prev) => ({
        ...prev,
        manager: "",
        fin_status: "",
        sale_year: "",
        invoice_year: "",
      }));
    }
  };

//   const handleCreate = async (data: ManOrder) => {
//     try {
//       await addManOrder(data).unwrap();
//       refetch();
//     } catch (error) {
//       console.error("Update failed: ", error);
//     }
//   };

  const handleSetPage = (page: number) => {
    setFilters((prev) => ({
      ...prev,
      page,
    }));
  };

  const resetConfigFilters = () => {
    setFilters(prev => ({
      ...prev,
      type: "",
      top: "",
      cabine: "",
      trans: "",
      susp: "",
      wheelbase: "",
      traction: "",
      engine: "",
      carload: "",
      setup: "",
      color: "",
    }));
  };

  if (isError) return <div>Error</div>;

  if (isLoading) return <div>Caricamento...</div>;

  return (
    <>
      <div className="flex min-h-screen">
        <div className="flex flex-col w-1/4">
          <FiltersForm 
            filters={filters} 
            handleChange={handleChange}
            resetConfigFilters={resetConfigFilters} 
          />
          <HistoryList />
        </div>
        <div className="w-3/4 p-4">
          <ManOrderList results={data?.results} isLoading={isLoading} />
          <Pagination currentPage={filters.page} totalPages={Math.ceil(data?.count / filters.page_size)} onPageChange={handleSetPage} />
          <div className="text-center mt-2">Totale: {data?.count}</div>
        </div>
      </div>
      {/* <CreateManOrderModal
        open={createIsOpen}
        onClose={() => setCreateIsOpen(false)}
        onSave={handleCreate}
      /> */}
    </>
  );
};
