import React, { useState } from "react";
import { useAddUsedMutation, useGetUsedsQuery } from "entities/used/api/api";
import { toSearchString } from "entities/used/api/utils";
import { FiltersForm } from "entities/used/ui/FiltersForm";
import { UsedList } from "entities/used/ui/UsedList";
import { Filters, NewUsed } from "entities/used/api/types";
import { CreateUsedModal } from "entities/used/ui/CreateUsedModal";
import { Pagination } from "shared/ui/Pagination";
import { HistoryList } from "entities/orders/ui/HistoryList";

export const UsedPage: React.FC = () => {
  const [filters, setFilters] = useState<Filters>({
    com_status: "in stock",
    category: "",
    dt_arrive_after: "",
    dt_arrive_before: "",
    safe_arrival: "",
    model: "",
    fis_status: "",
    topused: "",
    page_size: 9,
    page: 1,
    manager: "",
    ads: "",
  });

  const { data, isLoading, isError, refetch } = useGetUsedsQuery(
    toSearchString(filters)
  );

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const [
    addUsed,
    { isLoading: isUpdating, isError: isErrorUpdate, isSuccess },
  ] = useAddUsedMutation();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreate = async (data: NewUsed) => {
    try {
      await addUsed(data).unwrap();
      refetch();
    } catch (error) {
      console.error("Update failed: ", error);
    }
  };

  const handleSetPage = (page: number) => {
    setFilters((prev) => ({
      ...prev,
      page,
    }));
  };

  if (isError) alert("Errore");

  if (isLoading) return <div>Caricamento...</div>;

  return (
    <>
      <div className="flex min-h-screen">
        <div className="flex flex-col w-1/4">
          <FiltersForm filters={filters} handleChange={handleChange} />
          <button className="btn" onClick={() => setCreateIsOpen(true)}>
            Crea usato
          </button>
          <HistoryList />
        </div>
        <div className="w-3/4 p-4">
          <UsedList results={data.results} isLoading={isLoading} />
          <Pagination currentPage={filters.page} totalPages={Math.ceil(data.count / filters.page_size)} onPageChange={handleSetPage} />
          <div className="text-center mt-2">Totale:{data.count}</div>
        </div>

      </div>
      <CreateUsedModal
        open={createIsOpen}
        onClose={() => setCreateIsOpen(false)}
        onSave={handleCreate}
      />
    </>
  );
};
