import React, { useState } from 'react';
import { useAddManOrderMutation } from '../model/api';
import { ManOrder } from '../model/types';
import { useSearchParams } from 'react-router-dom';

interface NewManOrderProps {
	isOpen: boolean;
	onClose: () => void;
	onOrderCreated?: (orderId: string) => void;
}

export const NewManOrder: React.FC<NewManOrderProps> = ({ isOpen, onClose, onOrderCreated }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const currentQuoteId = searchParams.get("quote") || "";
	const [addManOrder] = useAddManOrderMutation();
	const [formData, setFormData] = useState<Partial<ManOrder>>({
		type: "MAN",
		model: 'TGX',
		status: 'preventivo',
		reserved: currentQuoteId,
	});

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const response = await addManOrder(formData as ManOrder).unwrap();
			if (onOrderCreated && response.id) {
				onOrderCreated(response.id);
			}
			onClose();
		} catch (error) {
			console.error('Errore durante la creazione dell\'ordine:', error);
		}
	};

	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
			<div className="bg-white rounded-lg p-6 w-full max-w-md">
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-xl font-bold">Veicolo da ordinare</h2>
					<button
						onClick={onClose}
						className="text-gray-500 hover:text-gray-700"
					>
						x
					</button>
				</div>

				<form onSubmit={handleSubmit} className="space-y-4">

					<div>
						<label htmlFor="model" className="text-xs text-gray-500">
							MODELLO
						</label>
						<select
							id="model"
							name="model"
							onChange={(e) =>
								setFormData({ ...formData, model: e.target.value })
							}
							className="w-full px-3 py-2 border border-gray-300 rounded shadow-sm"
						>
							<option value="TGX">TGX</option>
							<option value="TGS">TGS</option>
							<option value="TGM">TGM</option>
							<option value="TGL">TGL</option>
							<option value="TGE">TGE</option>
							<option value="SEM">SEM</option>
						</select>
					</div>


					<div className="flex justify-end space-x-3 mt-6">
						<button
							type="button"
							onClick={onClose}
							className="btn"
						>
							Annulla
						</button>
						<button
							type="submit"
							className="btn"
						>
							Crea
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};
