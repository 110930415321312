import { ManOrder } from "../model/types";

interface Props {
    manOrder: ManOrder
}

export const ProdStatus = ({ manOrder }: Props) => {
    const calcStatus = () => {
        const today = new Date();
        if (manOrder.dt_supply) return "fornito";
        if (manOrder.dt_product && (!manOrder.dt_end_edit || new Date(manOrder.dt_end_edit) >= today)) return "modificabile";
        if (manOrder.dt_end_edit && manOrder.dt_product && new Date(manOrder.dt_end_edit) < today && new Date(manOrder.dt_product) >= today) return "in produzione";
        if (manOrder.dt_product && new Date(manOrder.dt_product) <= today && !manOrder.dt_supply) return "in consegna";

        return "IN ATTESA";
    }
    return <div className="uppercase text-xs bg-blue-50 border border-gray-400 rounded-md p-1">{calcStatus()}</div>
}