import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Manager } from './types';

export const managersApi = createApi({
  reducerPath: 'managersApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: '/api',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `token ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getManagers: builder.query<Manager[], void>({
      query: () => '/managers',
    }),
    getSellers: builder.query<Manager[], void>({
        query: () => '/managers?role=seller', //if has order(s) con status venduto
      }),
  }),
  
});

export const { useGetManagersQuery, useGetSellersQuery } = managersApi; 