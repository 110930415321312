import { useGetHistoryQuery } from "../model/rtk-api";
import { HistoryCard } from "./HistoryCard";

export const HistoryList = () => {
    const { data: history } = useGetHistoryQuery(null);
    return (<>{history && history.history && history.history.length > 0 &&
        <div className="flex flex-col gap-2 mt-2">
            {history.history.map((h: any) => (
                h && <HistoryCard history={h} />
            ))}
        </div>
    }</>)
}