import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  createOrder,
  getCurrentOrder,
  modelList,
  openWindow,
  orderTypes,
  setNewOrder,
  setOrderType,
} from "entities/orders/model";
import { addOrderToQuote } from "entities/quotes/model/api";
import { getViewer } from "entities/viewer/model";

import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { arrToMap } from "shared/utils";
import { OrderCreateObject } from "types/order";

export const OrderCreate = () => {
  const dispatch = useAppDispatch();
  const owner = useAppSelector(getViewer);
  const [code, setCode] = useState<string | null>(null);
  const [model, setModel] = useState<string | null>(null);
  const [name, setName] = useState<string>("");
  const [type, setType] = useState<string>("MAN");
  const [modelDesc, setModelDesc] = useState<string>("");
  const [errorModel, setErrorModel] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentLead = useAppSelector(
    (state) =>
      arrToMap(state.leads.entities)[Number(searchParams.get("lead_id"))]
  );
  const quoteId = searchParams.get("quote");

  // if from crm
  useEffect(() => {
    if (currentLead && searchParams.get("lead_id")) setName(currentLead.name);
    else setName(type + " IN STOCK");
  }, [type, model]);

  const handlerCode = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };
  const handlerModel = (e: SelectChangeEvent) => {
    const value = e.target.value;
    if (value !== "") setErrorModel(false);
    setModel(value);
  };

  const handlerType = (e: SelectChangeEvent) => {
    const value = e.target.value;
    setType(value);
  };

  const handlerModelDesc = (e: ChangeEvent<HTMLInputElement>) => {
    setModelDesc(e.target.value);
  };

  const handleCreate = () => {
    const order: OrderCreateObject = {
      owner: owner ? owner.id : "",
      type: type,
      code: code,
      model: type === "MAN" ? model : type === "SEMIRIMORCHIO" ? "SEM" : "UST",
      model_desc: modelDesc,
      manual_calc: true,
      name: name,
      margin: type === "MAN" ? "3" : "0",
      trans_price: type === "MAN" ? "1800" : "0",
      setup_price: type === "MAN" ? "850" : "0",
      provision_price: type === "MAN" ? "300" : "0",
      seller_percent: type === "MAN" ? "1" : "0",
      opt_ext_margin: type === "MAN" ? "4" : "0",
      reserved: quoteId ? quoteId : null,
    };
    if ((type === "MAN" && !model) || (type !== "MAN" && modelDesc === "")) {
      setErrorModel(true);
    } else {
      dispatch(createOrder(order));
      dispatch(setNewOrder(false));
      dispatch(setOrderType(type));
      dispatch(openWindow("newOrderWindow"));
      setErrorModel(false);
    }
  };

  return (
    <>
      <Dialog open={true} fullWidth>
        <DialogTitle>NUOVO ORDINE {name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack direction={"column"} spacing={2}>
              <FormControl
                required
                sx={{ maxWidth: "200px", marginTop: "5px" }}
                error={errorModel && true}
              >
                <InputLabel id="type-label">Tipo ordine</InputLabel>
                <Select
                  labelId="type-label"
                  size="small"
                  label="Tipo ordine"
                  value={type ? type : "MAN"}
                  onChange={handlerType}
                >
                  {orderTypes.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {type === "MAN" && (
                <>
                  <FormControl sx={{ maxWidth: "180px" }}>
                    <TextField
                      onChange={handlerCode}
                      autoFocus
                      defaultValue="5005/"
                      margin="dense"
                      id="code"
                      label="AUFTRAG"
                      type="text"
                      fullWidth
                      variant="standard"
                      helperText="vuoto se non è ancora ordinato"
                    />
                  </FormControl>
                  <FormControl
                    required
                    sx={{ maxWidth: "120px" }}
                    error={errorModel && true}
                  >
                    <InputLabel id="model-label">Modello</InputLabel>
                    <Select
                      id="model_select"
                      labelId="model-label"
                      size="small"
                      label="Modello *"
                      value={model ? model : "None"}
                      onChange={handlerModel}
                    >
                      {modelList.map((item, index) => (
                        <MenuItem id={item} key={index} value={item}>
                          {item.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}

              {type !== "MAN" && (
                <FormControl
                  required
                  sx={{ maxWidth: "300px" }}
                  error={errorModel && true}
                >
                  <TextField
                    onChange={handlerModelDesc}
                    autoFocus
                    defaultValue=""
                    margin="dense"
                    id="model-desc"
                    label="Marca e modello"
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                </FormControl>
              )}
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dispatch(setNewOrder(false))}>CHIUDI</Button>
          <Button id="create_order" onClick={handleCreate}>
            CREA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
