import React from "react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const renderPageButtons = () => {
    if (totalPages <= 10) {
      return Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index}
          onClick={() => onPageChange(index + 1)}
          className={`p-1 ${
            currentPage === index + 1 ? "text-blue-500" : "bg-white"
          }`}
        >
          {index + 1}
        </button>
      ));
    }

    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= currentPage - 1 && i <= currentPage + 1)) {
        buttons.push(
          <button
            key={i}
            onClick={() => onPageChange(i)}
            className={`p-1 ${
              currentPage === i ? "text-blue-500" : "bg-white"
            }`}
          >
            {i}
          </button>
        );
      } else if (buttons[buttons.length - 1].type !== 'span' || (buttons[buttons.length - 1].props.children !== '...')) {
        buttons.push(<span key={`ellipsis-${i}`}>...</span>);
      }
    }
    return buttons;
  };

  return (
    <div className="flex justify-center items-center space-x-2 mt-4">
      <button
        onClick={handlePrevious}
        disabled={currentPage === 1}
        className={`p-1 ${
          currentPage === 1 ? "cursor-not-allowed text-white" : "text-gray-400"
        }`}
      >
        {"<"}
      </button>

      {renderPageButtons()}

      <button
        onClick={handleNext}
        disabled={currentPage === totalPages}
        className={`p-1 ${
          currentPage === totalPages
            ? "text-white cursor-not-allowed"
            : "text-gray-400"
        }`}
      >
        {">"}
      </button>
    </div>
  );
};
