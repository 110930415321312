import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Order } from "types/order";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/orders",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["orders", "order"],
  endpoints: (builder) => ({
    getOrderById: builder.query<Order, string | null>({
      query: (id) => id ? `${id}` : '',
      providesTags: (result, error, id) => [{ type: 'order', id: id || '' }],
    }),
    
    getOrders: builder.query<Order[], void>({
      query: () => '',
      providesTags: ['orders'],
    }),

    getHistory: builder.query({
      query: () => `/view_history`,
      providesTags: ["orders"],
    }),
    
    updateOrder: builder.mutation<Order, Partial<Order>>({
      query: (order) => ({
        url: `/${order.id}/`,
        method: "PUT",
        body: order,
      }),
      invalidatesTags: (result, error, order) => [
        { type: 'order', id: order.id },
        'orders'
      ],
    }),
  }),
});

export const {
  useGetOrderByIdQuery,
  useGetOrdersQuery,
  useUpdateOrderMutation,
  useGetHistoryQuery,
} = ordersApi;
