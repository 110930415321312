import { Orders } from "entities/orders/ui/Orders";

export const OrdersPage = () => {

  return (
    <>
      <Orders />
    </>
  );
};
