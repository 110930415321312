// NEW ORDER WINDOW

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from "@mui/material";

import Loader from "shared/ui/loader";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getOrderHistory,
  closeWindow,
} from "../model";

import { useEffect, useState } from "react";
import { OrderFiles } from "entities/files/ui";

import { resetFiles } from "entities/files/model";
import { Box } from "@mui/system";
import { Offers } from "entities/offers/ui";
import { Comments } from "entities/comments/ui";
import {
  getComments,
  getCommentsList,
  resetComments,
} from "entities/comments/model";
import { OrderActions } from "entities/actions/ui/OrderActions";
import { PriceCard } from "./PriceCard/PriceCard";
import { ConfigCard } from "./Configurator/ConfigCard";
import { StateCard } from "./StateCard/StateCard";
import { CalcCard } from "./CalcCard/CalcCard";
import { Badge } from "./Badge/Badge";
import { getRoles } from "entities/viewer/model";
import LanguageIcon from "@mui/icons-material/Language";

import { PublicsDetails } from "entities/publics/ui/PublicsDetails";
import { useLocation, useSearchParams } from "react-router-dom";
//import { Favorite } from "./Favorite/Favorite";
import { InvoicesList } from "entities/invoices/ui/InvoicesList";
import { addOrderToQuote, getQuote } from "entities/quotes/model/api";
import { getServices } from "entities/services/model/api";
import { Quotes } from "./Quotes";
import { useGetInvoicesQuery } from "entities/invoices/model/api";
import { UsedConfigCard } from "entities/used/ui/UsedConfigCard";
import { useCreatePublicMutation, useGetPublicQuery } from "entities/publics/model/api";
import { useGetHistoryQuery, useGetOrderByIdQuery } from "../model/rtk-api";

export const MANFields = [
  ["dt_end_edit", "Stop di modifiche:"],
  ["dt_product", "Consegna MAN:"],
  ["dt_planned", "Consegna cliente:"],
  ["dt_supply", "Fornito:"],
];

interface Props {
  withOffers?: boolean;
}

export const NewOrderWindow = ({
  withOffers = false,
}: Props) => {
  const [actionsOn, setActionsOn] = useState(false);
  //const [cancel, setCancel] = useState(false);
  const [showPublic, setShowPublic] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentOrderId = searchParams.get("order");
  const { data: order, isLoading: orderIsLoading, refetch: refetchOrder } = useGetOrderByIdQuery(
    currentOrderId ?? '',
    {
      skip: !currentOrderId
    }
  );

  const dispatch = useAppDispatch();

  const roles = useAppSelector(getRoles);
  const commentsList = useAppSelector(getCommentsList);

  const { data } = useGetInvoicesQuery(order?.id, {
    skip: !order,
  });
  const invoices = data ? data : [];

  const { data: pub } = useGetPublicQuery(order?.public ?? '', { skip: !order?.public })
  const [createPublic] = useCreatePublicMutation()
  const { refetch: refetchHistory } = useGetHistoryQuery(null);


  const currentUrl = useLocation().pathname;

  const closeHandler = async () => {
    dispatch(closeWindow("newOrderWindow"));
    dispatch(resetFiles());
    dispatch(resetComments());
    searchParams.delete("order");
    setSearchParams(searchParams);
    refetchHistory();
  };

  useEffect(() => {
    order && dispatch(getOrderHistory(order.id));
  }, [orderIsLoading]);

  useEffect(() => {
    order && dispatch(getComments(order.id));
  }, [orderIsLoading]);

  useEffect(() => {
    order && order.model === "UST" && setShowPublic(true)
  }, [order])

  const quote = searchParams.get("quote");

  const addOrderToQuoteHandler = async () => {
    if (quote && order) {
      dispatch(addOrderToQuote(quote, order.id));
      closeHandler();
      dispatch(closeWindow("orderStockWindow"));
      dispatch(getQuote(quote));
      dispatch(getServices(quote));
    }
  };

  const handleCreatePublic = async () => {
    if (!order) return;
    try {
      await createPublic(order).unwrap();
      refetchOrder();
    } catch (error) {
      console.error('Failed to create public:', error);
    }
  };

  if (orderIsLoading) return <Loader />;

  return (
    <>

      <Dialog open={true} fullWidth maxWidth="lg">
        {order && (
          <DialogContent>
            {/* -----BUTTONS------ */}

            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
              <div
                style={{
                  backgroundColor: "darkblue",
                  padding: "0.4rem",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                ref: {order.id}
              </div>
              {currentUrl.includes("/order") && (
                <Button variant="contained" onClick={() => closeHandler()}>
                  TORNA
                </Button>
              )}
              <Button
                id="button-history"
                variant="outlined"
                onClick={() => setActionsOn(true)}
              >
                STORIA
              </Button>

              {actionsOn && <OrderActions onClose={setActionsOn} />}

              {/* {roles?.includes("stock_manager") &&
                ["in stock", "incompleto"].includes(order.status) &&
                order.type === "MAN" && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setCancel(true)}
                  >
                    CANCELLA ORDINE
                  </Button>
                )} */}

              {roles?.includes("seller") && (
                <LanguageIcon
                  sx={{ color: showPublic ? "blue" : "grey" }}
                  onClick={() => setShowPublic(!showPublic)}
                />
              )}

              {/* {roles?.includes("holder") && <Favorite order={order} />} */}
            </Stack>

            {showPublic && roles?.includes("seller") && (
              <div>
                {pub && (
                  <Box marginTop={3}>
                    {pub && <PublicsDetails current={pub} />}
                  </Box>
                )}
                {!pub && (
                  <div style={{ marginTop: "20px", fontWeight: "lighter" }}>
                    Per questo ordine l'annuncio non è ancora creato.{" "}
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={handleCreatePublic}
                    >
                      Crea adesso.
                    </span>
                  </div>
                )}
              </div>
            )}

            <Grid container direction="row" spacing={2} marginTop={1}>
              <Grid item md={4} xs={12}>
                <Stack spacing={1}>
                  <Badge order={order} />
                  {order.type === "MAN" && <ConfigCard order={order} />}
                  {order.type === "MAN" && <StateCard order={order} />}
                  {order.model === "UST" && <UsedConfigCard usedId={order.used_id} />}
                </Stack>
              </Grid>

              {/* -----INPUT TABLE------ */}

              <Grid item md={4} xs={12}>
                <PriceCard order={order} />
              </Grid>

              {/* -----OUTPUT TABLE------ */}

              <Grid item md={4} xs={12}>
                <Stack direction="column" spacing={1}>
                  {(order.status === "venduto" ||
                    order.status === "fatturato") &&
                    (roles?.includes("crm_manager") ||
                      roles?.includes("seller")) && <CalcCard order={order} />}

                  {/* -----OFFERS------
                  {(roles?.includes("crm_viewer") ||
                    roles?.includes("seller")) &&
                    withOffers && <Offers />} */}

                  {order.actual_leads.length > 0 && (
                    <Stack sx={{ fontSize: "0.8rem" }}>
                      {order.actual_leads.map((item: any) => (
                        <div key={item.id}>
                          LEAD #{item.id} {item.owner}
                        </div>
                      ))}
                    </Stack>
                  )}

                  <Quotes quotes={order.quotes} />

                  {/* <Button variant="outlined">LEAD</Button> */}

                  {/* -----INVOICES------ */}
                  {/* <OldInvoicesList /> */}
                  {invoices.length > 0 && (
                    <>
                      <div style={{ fontSize: "12px" }}>FATTURA D'ACQUISTO</div>
                      <InvoicesList list={invoices} />
                    </>
                  )}
                </Stack>
              </Grid>
            </Grid>

            {/* -----FILE UPLOADS------ */}

            <Box marginTop={3}>
              <OrderFiles orderId={order.id} />
            </Box>

            {/* -----COMMENTS------ */}

            <Box marginTop={3}>
              <Comments list={commentsList} />
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          {quote && (
            <Button
              id="add_order_to_quote"
              disabled={order && order.reserved ? true : false}
              color="success"
              variant="contained"
              onClick={() => addOrderToQuoteHandler()}
            >
              Agg. al preventivo #{quote}
            </Button>
          )}
          <Button variant="contained" onClick={() => closeHandler()}>
            CHIUDI
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
