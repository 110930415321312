import { useAppDispatch, useAppSelector } from "app/hooks";
import { Quote } from "../model/quoteSlice";
import { useSearchParams } from "react-router-dom";
import { QuoteOrderCard } from "./QuoteOrderCard";
import { OrderStockWin } from "entities/orders/ui/OrderStockWin";
import { getOrder, openWindow, setCurrent } from "entities/orders/model";
import { Stack } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useEffect, useState } from "react";
import { NewManOrder } from "entities/man/ui/NewManOrder";
import { addOrderToQuote } from "../model/api";
import { getServices } from "entities/services/model/api";

interface Props {
  quote: Quote;
}

export const QuoteOrderList = ({ quote }: Props) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const orders = useAppSelector((state) => state.orders.entities);

  // se ordine non c'e in state carica ordine
  useEffect(() => {
    quote.orders.forEach((orderId) => {
      const o = orderId.toString();
      if (!Object.keys(orders).includes(o)) dispatch(getOrder(orderId));
    });
  }, [orders]);

  const selectOrderHandler = () => {
    dispatch(openWindow("orderStockWindow"));
    searchParams.set("quote", quote.id);
    setSearchParams(searchParams);
  };

  const onOrderCreated = (orderId: string) => {
    dispatch(addOrderToQuote(quote.id, orderId));
    searchParams.set("order", orderId);
    setSearchParams(searchParams);
    dispatch(setCurrent(orderId));
    dispatch(openWindow("newOrderWindow"));
  }

  return (
    <div>
      {Object.values(orders).length > 0 ? (
        <Stack spacing={1}>
          {quote.orders.map((item: any) => (
            <QuoteOrderCard key={item} quote={quote} order={orders[item]} />
          ))}
        </Stack>
      ) : (
        <div> Non ci sono i veicoli nel preventivo</div>
      )}
      {quote.editable && (
        <div>
          <div>Aggiungi veicoli</div>
          <div className="flex items-center gap-4">
            <div
              className="btn w-40 text-center"
              onClick={() => selectOrderHandler()}
            >
              VEICOLI IN STOCK
            </div>
            <div
              className="btn w-40 text-center"
              onClick={() => setIsModalOpen(true)}
            >
              DA ORDINARE
            </div>
          </div>
        </div>
      )}
      <OrderStockWin />
      <NewManOrder isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onOrderCreated={onOrderCreated} />
    </div>
  );
};
