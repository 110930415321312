import { useAppDispatch, useAppSelector } from "app/hooks";
import { Order } from "types/order";
import { Quote } from "../model/quoteSlice";
import { removeOrderFromQuote } from "../model/api";
import { PriceCard } from "entities/orders/ui/PriceCard/PriceCard";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import { openWindow, ordersState, setCurrent } from "entities/orders/model";
import { ServiceList } from "../../services/ui/ServiceList";
import { useSearchParams } from "react-router-dom";

interface Props {
  order: Order;
  quote: Quote;
}

export const QuoteOrderCard = ({ order, quote }: Props) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(ordersState);
  const [showCalc, setShowCalc] = useState(false);
  const [deleteWindow, setDeleteWindow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  //const currentOrderId = useAppSelector((state) => state.orders.current);

  const openOrderHandler = () => {
    if (order) {
      dispatch(openWindow("newOrderWindow"));
      searchParams.set("order", order.id);
      setSearchParams(searchParams);
      dispatch(setCurrent(order.id));
    }
  };
  if (loading) return <>loading...</>;
  return (
    <Stack
      style={{
        padding: "0.5rem",
        border: "1px dotted grey",
        position: "relative",
        overflowX: "auto",
        whiteSpace: "nowrap",
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        {order && (
          <div
            style={{ fontSize: "1rem", color: "blue", cursor: "pointer" }}
            onClick={() => openOrderHandler()}
          >
            #{order.id}{" "}
            <span style={{}}>
              {order.code ? order.code : order.model_desc}{" "}
            </span>
            <span>
              {order.model}{" "}
            </span>
            <span>
              {order.abbreviation}{" "}
            </span>
            <span
              style={{
                color: order.status === "venduto" ? "darkred" : "darkgreen",
              }}
            >
              ({order.status.toUpperCase()})
            </span>
            {order.reserved && (
              <span style={{ color: "darkred" }}>
                {" "}
                ACCETTATO PREV #{order.reserved}
              </span>
            )}
          </div>
        )}
      </Stack>
      {order && <ServiceList quote={quote} order={order} />}

      {showCalc && (
        <Dialog open={showCalc}>
          <DialogContent>
            <div style={{ width: "300px" }}>
              <PriceCard order={order} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowCalc(false)}>Ok</Button>
          </DialogActions>
        </Dialog>
      )}
      {/* {currentOrderId && <OrderWindow withOffers={false} />} */}
      {quote.editable && (
        <div
          style={{
            color: "darkred",
            fontSize: "0.8rem",
            position: "absolute",
            right: "0.5rem",
            bottom: "0.5rem",
            cursor: "pointer",
          }}
          onClick={() => setDeleteWindow(true)}
        >
          Elimina
        </div>
      )}
      <Dialog open={deleteWindow}>
        <DialogContent>
          Sei sicuro di voler eliminare questo veicolo?{" "}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setDeleteWindow(false)}>
            TORNA
          </Button>
          <Button
            variant="outlined"
            onClick={() => dispatch(removeOrderFromQuote(quote.id, order.id))}
          >
            Sono sicuro
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
