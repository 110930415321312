import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getOrder,
  openWindow,
  ordersState,
  setCurrent,
} from "entities/orders/model";

import { OrderWindow } from "entities/orders/ui/OrderWindow";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "shared/ui/loader/loader";

export const OrderPage = () => {
  const dispatch = useAppDispatch();
  const orderId = useParams().orderId;

  const { current, loading } = useAppSelector(ordersState);

  useEffect(() => {
    dispatch(setCurrent(orderId));
    dispatch(getOrder(orderId));
    dispatch(openWindow("newOrderWindow"));
  }, [orderId]);

  if (loading) return <Loader />;

  return <>{current && <OrderWindow />}</>;
};
