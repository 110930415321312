import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MainMenuPopup from "./menu";
import AccMenuPopup from "./accmenu";
import { Button, Link, Stack, Switch, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { push } from "redux-first-history";
import { useAppDispatch, useAppSelector } from "app/hooks";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { resetOrderList, setFavorites } from "entities/orders/model";
import { ReactComponent as Logo } from "./man_logo.svg";
import { getRoles } from "entities/viewer/model";

export default function MenuAppBar() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.viewer.user);
  const roles = useAppSelector(getRoles);
  const location = useLocation();
  const favorites = useAppSelector((state) => state.orders.favorites);

  const favoritesHandler = () => {
    dispatch(setFavorites(!favorites));
    dispatch(push("/orders"));
  };
  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Link component={RouterLink} to="/">
            <Logo />
          </Link>
          <Stack direction="row" alignItems="center" spacing={1}>
            <MainMenuPopup />
            {user?.roles.includes("seller") && (
              <>

                <Link
                  component={RouterLink}
                  to="/orders"
                  color="white"
                  underline="none"
                  onClick={() => dispatch(resetOrderList())}
                >
                  Ordini
                </Link>
                <div>/</div>
                <Link
                  component={RouterLink}
                  to="/man"
                  color="white"
                  underline="none"
                >
                  MAN
                </Link>
                <div>/</div>
                {roles?.includes("used_manager") && (
                  <>

                    <Link
                      component={RouterLink}
                      to="/used"
                      color="white"
                      underline="none"
                    >
                      Usati
                    </Link>
                    <div>/</div>
                  </>

                )}


                <Link
                  component={RouterLink}
                  to="/crm"
                  color="white"
                  underline="none"
                >
                  CRM
                </Link>
              </>
            )}
            {/* {user?.roles.includes("holder") && (
              <div
                style={{ color: "white" }}
                onClick={() => favoritesHandler()}
              >
                {favorites ? <StarIcon /> : <StarOutlineIcon />}
              </div>
            )} */}
            <AccMenuPopup />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
