import { Navigate, Route, Routes } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { OrdersPage } from "./ordersPage";
import { ManageXlsPage } from "./manageXlsPage";
import { Orders } from "./reportsPage/Orders";
import Login from "entities/viewer/ui/login";
import { ReactElement, useEffect } from "react";
import { getCurrentViewer, getRoles, viewerState } from "entities/viewer/model";
import Loader from "shared/ui/loader/loader";
import { OffersPage } from "./offersPage";
import { Sellers } from "./reportsPage/Sellers";
import { Reports } from "./reportsPage/Reports";
import { Grafics } from "./reportsPage/Grafics";
import { OrderPage } from "./orderPage/OrderPage";
import { ActionsPage } from "./actionsPage/ActionsPage";
import { TasksPage } from "../entities/tasks/ui/TasksPage";
import { CancelledOrdersPage } from "./ordersPage/CancelledOrdersPage";
import { OrderPhotoPage } from "./orderPhotoPage/OrderPhotoPage";
import { WhatsApp } from "shared/ui/whatsApp";
import { CrmPage } from "./crmPage";
import { Dashboard } from "entities/dashboard/ui/Dashboard";
import { push } from "redux-first-history";
import { StartPage } from "./startPage";
import { InvoicesPage } from "./invoicesPage";
import { PublicsPage } from "../entities/publics/ui/publicsPage";
import { CompaniesPage } from "./CompaniesPage";
import { ContactsPage } from "./ContactsPage";
import { ExtData } from "entities/extdata/ui/ExtData";
import { OpenApiSearch } from "./OpenApiSearch";
import { CrmBoard } from "entities/crm/leads/ui/CrmBoard";
import { CalendarPage } from "./CalendarPage";
import { UsedPage } from "../entities/used/ui/UsedPage";
import { VehiclesPage } from "./vahiclesPage";
import { ManPage } from "entities/man/ui/ManPage";
//import { FileManagerPage } from "./fileManager/fileManagerPage";

interface ProtectedRouteInt {
  role: string;
  children: ReactElement;
}

const ProtectedRoute = ({ role, children }: ProtectedRouteInt) => {
  const userRoles = useAppSelector(getRoles);
  if (!userRoles?.includes(role)) {
    return <Navigate to="/" replace />;
  }
  return children;
};

export const Routing = () => {
  const dispatch = useAppDispatch();
  const { logged_in, loading } = useAppSelector(viewerState);
  useEffect(() => {
    if (!logged_in) dispatch(getCurrentViewer());
  }, [dispatch, logged_in]);

  if (loading) return <Loader />;

  if (!logged_in) return <Login />;

  return (
    <Routes>
      <Route path="/" element={<StartPage />} />
      <Route path="/orders" element={<OrdersPage />} />
      <Route path="/used" element={<UsedPage />} />
      <Route path="/man" element={<ManPage />} />
      <Route path="/vehicles" element={<VehiclesPage />} />
      <Route path="/vehicles/:type" element={<VehiclesPage />} />
      {/* <Route path="/orders/stock" element={<OrdersPage status="in stock" />} />
      <Route path="/orders/saled" element={<OrdersPage status="venduto" />} />
      <Route path="/orders/new" element={<OrdersPage status="incompleto" />} />
      <Route path="/orders/search" element={<OrdersPage status="search" />} /> */}
      <Route path="/cancelled" element={<CancelledOrdersPage />} />
      <Route path="/order/:orderId" element={<OrderPage />} />
      <Route path="/order/:orderId/:subpage" element={<OrderPage />} />
      <Route path="/order/:orderId/photos" element={<OrderPhotoPage />} />
      <Route path="/login" element={<Login />} />
      {/* <Route path="/truck" element={<OrdersPage archive={false} />} />
      <Route path="/van" element={<OrdersPage archive={false} />} /> */}
      <Route path="/preventivi" element={<OffersPage />} />
      <Route path="/whatsapp" element={<WhatsApp />} />
      <Route path="/crm" element={<CrmPage />} />
      <Route path="/crmboard" element={<CrmBoard />} />
      <Route path="/extdata" element={<ExtData />} />
      <Route path="/companies" element={<CompaniesPage />} />
      <Route path="/contacts" element={<ContactsPage />} />
      <Route path="/openapi" element={<OpenApiSearch />} />
      

      {/* <Route path="/files" element={<FileManagerPage />} /> */}

      <Route
        path="/cal"
        element={
          <ProtectedRoute role="calendar_manager">
            <CalendarPage />
          </ProtectedRoute>
        }
      />

      <Route
        path="/xls"
        element={
          <ProtectedRoute role="stock_manager">
            <ManageXlsPage />
          </ProtectedRoute>
        }
      />
      {/* <Route path="/archivio" element={<OrdersPage archive={true} />} /> */}
      <Route
        path="/reports"
        element={
          <ProtectedRoute role="reports_viewer">
            <Reports />
          </ProtectedRoute>
        }
      >
        <Route path="ordini" element={<Orders />} />
        <Route path="venditori" element={<Sellers />} />
        <Route path="graffici" element={<Grafics />} />
      </Route>
      <Route path="/actions" element={<ActionsPage />} />

      <Route
        path="/dashboard"
        element={
          <ProtectedRoute role="reports_viewer">
            <Dashboard />
          </ProtectedRoute>
        }
      ></Route>

      <Route
        path="/tasks"
        element={
          <ProtectedRoute role="holder">
            <TasksPage />
          </ProtectedRoute>
        }
      ></Route>

      <Route
        path="/invoices"
        element={
          <ProtectedRoute role="invoice_manager">
            <InvoicesPage />
          </ProtectedRoute>
        }
      ></Route>

      <Route
        path="/publics"
        element={
          <ProtectedRoute role="public_manager">
            <PublicsPage />
          </ProtectedRoute>
        }
      ></Route>
    </Routes>
  );
};
