import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Config } from "types/order";
import { initConfig } from './constants';

interface ConfiguratorProps {
  model: string;
  filter?: boolean;
  config: Config;
  setConfig: (config: Config) => void;
}

interface SelectConfigProps {
  field: string;
  value: string;
  label: string;
  list: string[];
  filter: boolean;
}

const tgxTypes = ["TRATTORE", "CARRO"];
const tgsTypes = ["TRATTORE", "CARRO", "MEZZO D'OPERA"];
const tgxCabineTypes = ["GX", "GM", "GN"];
const tgsCabineTypes = ["TM", "TN", "NN"];
const tgmTglCabineTypes = ["TM", "TN", "CC"];
const suspensionTypes = ["BL", "LL", "BB"];
const tgeTypes = ["FURGONE", "TELAIO CAB.", "TELAIO DOPP.CAB."];
const tgeTopTypes = ["BASSO", "ALTO", "ALTISSIMO"];
const tgeTraction = ["ANT.", "4 X 4", "POST."];
const tgeWheelbase = ["LUNGO", "CORTO"];
const transTypes = ["MANUALE", "AUTO"];
const tgxEngines = ["400", "440", "480", "520", "540", "580", "640"];
const tgsEngines = [
  "330",
  "360",
  "400",
  "440",
  "480",
  "520",
  "540",
  "580",
  "640",
];
const tglEngines = ["160", "190", "220", "250"];
const tgmEngines = ["250", "290", "320"];
const tgeEngines = ["100", "140", "160", "180"];
const tgxCarloads = ["18", "26", "35"];
const tgsCarloadsTrattore = ["18", "33"];
const tgsCarloadsCarro = ["18", "26", "35"];
const tgsCarloadsMezzo = ["33", "41"];
const tgeSetups = ["CASSONE FISSO", "CASSONE RIB.", "CELLA FRIGO"];
//const tgsSetup = ["RIBALTABILE"]
const colors = [
  "ROSSO",
  "GRIGIO",
  "BIANCO",
  "VERDE",
  "GIALLO",
  "NERO",
  "BLU",
  "MARRONE",
  "ALTRO",
];

export const Configurator = ({
  model,
  config,
  setConfig,
  filter = true,
}: ConfiguratorProps) => {
  const safeConfig = config || initConfig;

  const onChangeSelect = (event: SelectChangeEvent, field: string) => {
    setConfig({ 
      ...safeConfig,
      [field]: event.target.value 
    });
  };

  const resetFilter = (field: string) => {
    setConfig({ 
      ...safeConfig,
      [field]: "" 
    });
  };

  const SelectConfig = ({
    field,
    value,
    list,
    label,
    filter,
  }: SelectConfigProps) => {
    return (
      <FormControl
        size="small"
        fullWidth
      >
        <InputLabel id="label">{label}</InputLabel>
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Select
            fullWidth
            labelId="label"
            value={value}
            label={label}
            onChange={(event: SelectChangeEvent) =>
              onChangeSelect(event, field)
            }
          >
            {filter && <MenuItem value="">-</MenuItem>}
            {list.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <ClearIcon
            sx={{ color: "grey", cursor: "pointer" }}
            onClick={() => resetFilter(field)}
          />
        </Stack>
      </FormControl>
    );
  };

  return (
    <Box sx={{ maxWidth: 300 }}>
      <Stack direction="column" spacing={3}>
        {model === "TGX" && (
          <>
            <SelectConfig
              field="type"
              value={safeConfig.type}
              label="Tipo"
              list={tgxTypes}
              filter={filter}
            />

            <SelectConfig
              field="cabine"
              value={safeConfig.cabine}
              label="Cabina"
              list={tgxCabineTypes}
              filter={filter}
            />

            <SelectConfig
              field="engine"
              value={safeConfig.engine}
              label="Motore, hp"
              list={tgxEngines}
              filter={filter}
            />
          </>
        )}

        {model === "TGX" && safeConfig.type === "CARRO" && (
          <SelectConfig
            field="carload"
            value={safeConfig.carload}
            label="Portata"
            list={tgxCarloads}
            filter={filter}
          />
        )}

        {model === "TGS" && safeConfig.type !== "" && (
          <>
            <SelectConfig
              field="cabine"
              value={safeConfig.cabine}
              label="Cabina"
              list={tgsCabineTypes}
              filter={filter}
            />

            <SelectConfig
              field="engine"
              value={safeConfig.engine}
              label="Motore, hp"
              list={tgsEngines}
              filter={filter}
            />
            {safeConfig.type === "TRATTORE" && (
              <SelectConfig
                field="carload"
                value={safeConfig.carload}
                label="Portata"
                list={tgsCarloadsTrattore}
                filter={filter}
              />
            )}

            {safeConfig.type === "CARRO" && (
              <SelectConfig
                field="carload"
                value={safeConfig.carload}
                label="Portata"
                list={tgsCarloadsCarro}
                filter={filter}
              />
            )}

            {safeConfig.type === "MEZZO D'OPERA" && (
              <SelectConfig
                field="carload"
                value={safeConfig.carload}
                label="Portata"
                list={tgsCarloadsMezzo}
                filter={filter}
              />
            )}
          </>
        )}

        {(model === "TGL" || model === "TGM") && (
          <SelectConfig
            field="cabine"
            value={safeConfig.cabine}
            label="Cabina"
            list={tgmTglCabineTypes}
            filter={filter}
          />
        )}

        {model === "TGL" && (
          <SelectConfig
            field="engine"
            value={safeConfig.engine}
            label="Motore, hp"
            list={tglEngines}
            filter={filter}
          />
        )}

        {model === "TGM" && (
          <SelectConfig
            field="engine"
            value={safeConfig.engine}
            label="Motore, hp"
            list={tgmEngines}
            filter={filter}
          />
        )}

        {model && model !== "TGE" && (
          <>
            <SelectConfig
              field="susp"
              value={safeConfig.susp}
              label="Sospensioni"
              list={suspensionTypes}
              filter={filter}
            />
          </>
        )}

        {model === "TGE" && (
          <>
            <SelectConfig
              field="type"
              value={safeConfig.type}
              label="Tipo"
              list={tgeTypes}
              filter={filter}
            />

            {safeConfig.type === "FURGONE" && (
              <SelectConfig
                field="top"
                value={safeConfig.top}
                label="Tetto"
                list={tgeTopTypes}
                filter={filter}
              />
            )}

            <SelectConfig
              field="wheelbase"
              value={safeConfig.wheelbase}
              label="Passo"
              list={tgeWheelbase}
              filter={filter}
            />

            <SelectConfig
              field="traction"
              value={safeConfig.traction}
              label="Trazione"
              list={tgeTraction}
              filter={filter}
            />

            <SelectConfig
              field="trans"
              value={safeConfig.trans}
              label="Cambio"
              list={transTypes}
              filter={filter}
            />

            <SelectConfig
              field="engine"
              value={safeConfig.engine}
              label="Motore, hp"
              list={tgeEngines}
              filter={filter}
            />

            <SelectConfig
              field="setup"
              value={safeConfig.setup}
              label="Allestimento"
              list={tgeSetups}
              filter={filter}
            />
          </>
        )}
        <SelectConfig
          field="color"
          value={safeConfig.color}
          label="Colore"
          list={colors}
          filter={filter}
        />
      </Stack>
    </Box>
  );
};
